<template>
  <div class="p-4 output-html has-text-centered" style="width: 100%; height: 100px;" v-html="output"></div>
</template>

<script>
export default {
  components: {},
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      output: "",
    };
  },
  computed: {
    
  },
  sockets: {
    controllerData(data){
      if(data.name == this.block.blockName){
        this.output = data.output
      }
    }
  },
  methods: {},
  mounted() {
    //
  },
};
</script>

